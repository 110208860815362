.monthly-details-widget {
  background: rgb(211,220,240);
  background: linear-gradient(83deg, rgba(211,220,240,1) 0%, rgba(212,212,250,1) 35%, rgba(235,222,255,1) 100%);
  border: thick double #7a60ee !important;

  & {
    .ant-collapse-header {
      padding-bottom: 0 !important;
    }
  }
}