@screen-sm-min: 576px; //, or you can choose another break point
@media only screen and (max-width: @screen-sm-min) {
	.ant-calendar-range {
		width: 276px;

		.ant-calendar-range-part {
			width: 100%;
		}

		.ant-calendar-range-right {
			float: left;
			border-top: 1px solid #e8e8e8;
		}
	}
}
