.meeting-timing-badge {
  .gx-timeline-badge();
  border-radius: 20%;
  margin: auto;
  &:after {
    display: none;
  }
}

.alternate-child-colors {
  & > .gx-timeline-item:nth-of-type(even) {
    & > .gx-timeline-panel {
      background-color: rgb(46, 48, 139);
      box-shadow: 0 0 5px 2px rgba(0,0,0,.35);
    }
  }
  & > .gx-timeline-item:nth-of-type(odd) {
    & > .gx-timeline-panel {
      background-color: rgb(70, 72, 216);
      box-shadow: 0 0 5px 2px rgba(0,0,0,.35);
    }
  }
}
