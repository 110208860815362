.ec-recommendation-item {
  min-width: 250px;
  height: 200px;
  overflow-y: scroll;
  padding-top: 2em;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
