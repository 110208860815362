.teacher-performance-drawer {
  margin: 41px 12px 0 12px;
  .table-columns {
    display: grid;
    grid-template-columns: 10% 40% 1fr 1fr 1fr;
    margin-bottom: 10px;
  }
  .table-body {
    & .ant-radio-group {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .row {
      display: grid;
      grid-template-columns: 10% 40% 60%;
      padding: 10px;
      border-top: 1px solid #e8e8e8;
    }
  }
}

.save-btn{
  font-size: 22px;
}